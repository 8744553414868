body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea:focus, input:focus{
    outline: none;
}

.lineHeight30 {
  line-height: 36px;
}

.lineHeight50 {
  line-height: 50px;
}

.lineHeight68 {
  line-height: 68px;
}

.lineHeight60 {
  line-height: 60px;
}

.lineHeight100 {
  line-height: 100px;
}


.shadow2dp {
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.12))
  drop-shadow(0 3px 1px rgba(0, 0, 0, 0.14))
  drop-shadow(0 1px 5px rgba(0, 0, 0, 0.12))
  drop-shadow(0 -1px 2px rgba(0, 0, 0, 0.1));
}

/**
* Shows how you can use CSS to style your Element's container.
*/
.MyCardElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.MyCardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.MyCardElement--invalid {
  border-color: #fa755a;
}

.MyCardElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.AUtosizeTextarea:focus {
    outline: none !important;
    /* border:1px solid red; */
    border: none;
    background-color: #D3DEF1;
    /* box-shadow: 0 0 10px #719ECE; */
}

.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.nonfocus:focus {
  outline: none !important;
  outline-offset: none !important;
}

.mouseoverbg:hover {
  font-weight: bold;
}

.bg-orange1 {
  background-color: #FB7E25;
}

.bg-red1 {
  background-color: #F05021;
}

.mouse1:hover {
  background:linear-gradient(transparent 70%, #FFFF00 0%);
}

.underline_01 {
  background:linear-gradient(transparent 70%, #FFFF00 0%);
}


.fadeOut{
     opacity:0;
     /* width:0;
     height:0; */
     transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

}
.fadeIn{
     opacity:1;
     /* width:100px;
     height:100px; */
     transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}



/**
 * https://github.com/i-like-robots/react-tags
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-wrapper">
 *       <input class="react-tags__search-input" />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div>
 */
.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.chatbody {
  overflow-x: auto;
}

.chatOuter {
  /* display: flex; */
  /* flex-direction: column; */
  /* height: 250px; */
  overflow-y: scroll;
  flex-shrink: 0;
}

.chatInner {
  display: flex;
  flex-direction: column-reverse;
  /* flex-grow: 1; */
  flex-shrink: 0;
}

.overflowScrool2 {
  overflow-y: scroll;
  height: 100%;
  flex-shrink: 0;
}

.overflowScrool1 {
  /* height: 500px; */
  /* position: absolute; */
  flex-grow: 1;
  overflow-y: scroll;
  flex-shrink: 0;
  display: flex;
  flex-direction: column-reverse;
  /* justify-content: flex-start; */
}

.overflowScrool3 {
  flex-shrink: 0;
  overflow-y: auto;
}

.overflowScrool5 {
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: 　0;
  overflow-y: scroll;
}


.editorPlayed {
  /* background-color: #FFB6C1; */
  /* color: #D35030; */
  font-weight: bold;
  color: #000000;
  /* background: linear-gradient(transparent 40%, #ccffd5 40%, #80ff97 80%, #ccffd5 100%); */
}


.headlineButtonWrapper {
  display: inline-block;
}

.headlineButton {
  background: #fbfbfb;
  color: #888;
  font-size: 18px;
  border: 0;
  /* padding-top: 5px; */
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
  background: #f3f3f3;
}

.MyCustomBlock {
  text-decoration: line-through;
}

.demo02 {
  opacity: 0.1;
}
.demo02:hover {
  opacity: 1;
}

input:focus, textarea:focus, select:focus{
    outline: none;
}
*:focus {
    outline: none;
}

/* アニメーションを定義する */
@keyframes fade-in {
  0% {
    opacity: 0; /* 透明度を0にする */
  }
  100% {
    opacity: 1; /* 透明度を1にする */
  }
}

/* テキストにアニメーションを適用する */
.fade-in-text {
  animation-name: fade-in; /* 使用するアニメーションの名前を指定する */
  animation-duration: 2s; /* アニメーションの時間を指定する */
  animation-fill-mode: forwards; /* アニメーション終了時に最後のスタイルを保持する */
}

