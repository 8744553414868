/* .cs-message--outgoing .cs-message__content {
  background-color: #FFD2D7;
}
.cs-message.cs-message--outgoing.cs-message--first .cs-message__content {
  background-color: #FFD2D7;
} */


.cs-message--outgoing .cs-message__content {
  background-color: #FFD2D7;
}
.cs-message--incoming .cs-message__content{
  background-color: #C7E3FA;
}

.cs-message__content {
  background-color: rgba(0,0,0,0);
}